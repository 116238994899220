import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Medailon, PaddingWrapper, H2 } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/hvezdarna/logohvezdarna2.png"
import Top from "../../../images/reference/hvezdarna/top.jpg"
import Dusek from "../../../images/reference/hvezdarna/reaction-marketing.png"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"

class Hvezdarna extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Reference na marketingové práce pro Hvězdárnu a Planetárium Brno |
            igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceHeader
          companyName="Hvězdárna a planetárium Brno"
          heading="Reference na marketingové práce pro Hvězdárnu a planetárium Brno"
          date="listopad - prosinec 2015"
        />

        <ReferenceImageText
          right
          img={Top}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo Hvězdárny a planetária Brno"
          link="//hvezdarna.cz/"
        >
          <p>
            Hvězdárna a planetárium Brno je centrum pro popularizaci vědy ve
            srozumitelné, zábavné a interaktivní podobě. Každý rok do ní zavítá
            více než 100 000 návštěvníků. Jejím zřizovatelem je statutární město
            Brno.
          </p>
          <PaddingWrapper>
            <h2>Zadání</h2>
            <p>Zvýšit celkové povědomí o Hvězdárně mezi širokou veřejností.</p>
          </PaddingWrapper>
        </ReferenceImageText>

        <ReferenceImageText>
          <PaddingWrapper>
            <H2>Rozsah projektu</H2>
            <p>
              propagace unikátního obsahu na sociálních sítích; intenzivní
              videokampaň; ucelená komunikační kampaň napříč Facebookem,
              Twitterem a Instagramem
            </p>
          </PaddingWrapper>
          <PaddingWrapper>
            <H2>Zhodnocení zadavatele</H2>
            <p>
              Rychlé a efektivní jednání, samostatnost, ale také nezbytná zpětná
              vazba. Nemohu si stěžovat, takto si představuji spolupráci ve
              světě sociálních sítí.
            </p>
          </PaddingWrapper>
        </ReferenceImageText>

        <Medailon personName="Jiří Dušek" position="ředitel" img={Dusek}>
          <p>
            Rychlé a efektivní jednání, samostatnost. Takto si představuji
            spolupráci.
          </p>
        </Medailon>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Hvezdarna
